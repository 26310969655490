import React from 'react';

class Feedback extends React.Component {
    render() {
        return (
            <div class="feedback-popup">
                <span class="title">
                    Feedback?
                </span>
                <i class="fa fa-chevron-up action-expand-feedback-popup"></i>
                <span class="text">
                    Hey there! I'd love to hear your feedback!
                </span>
                <span class="success-text">
                    Thanks for the message! If you left an email address, I will get back to you soon.
                </span>
                <input type="text" placeholder="Name" class="name" />
                <input type="email" placeholder="Email (optional)" class="email" />
                <textarea placeholder="Write your message" class="message"></textarea>

                <div class="button action-feedback-popup-send">Send</div>
                <span class="text grey">
                    Feedback form made by <a href="https://twitter.com/levelsio">levelsio</a>
                </span>
            </div>

        )
    }
}

export { Feedback };
